import * as types from '../mutations/mutation-types'
export const actionsHelpers = {

  randomID: function () {
    let text = ''
    const initial = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
    const initialLength = initial.length
    const seed = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    const seedLength = seed.length
    for (let i = 0; i < 8; i++) {
      // javascript doesn't like IDs that begin with numbers
      if (i > 0) {
        text += seed.charAt(Math.floor(Math.random() * seedLength))
      } else {
        text += initial.charAt(Math.floor(Math.random() * initialLength))
      }
    }

    return text
  },
  /**
   *
   * sets loading status
   *
   * @todo tie to specific component / callee rather than global.
   * we may not always want to show global loading overlay
   *
   * @param {*} context
   * @param {*} id random id generated at beginning of action call
   */
  initStatus: function (context, id) {
    context.commit(`Global/${types.SET_LOADING_STATUS}`, {
      id: id,
      status: true
    }, {
      root: true
    })
  },
  /**
   *
   * @param {*} context
   * @param {*} id random id generated at beginning of action call
   */
  unsetLoadingStatus: function (context, id) {
    context.commit(`Global/${types.SET_LOADING_STATUS}`, {
      id: id,
      status: false
    }, {
      root: true
    })
  },
  /**
   *
   * takes the error from the API and commits
   * message which triggers a toast
   *
   * @param {*} error message from the API response
   * @param {*} context
   */
  handleError: function (error, context) {
    context.commit(`Global/${types.SET_ERRORS}`, error.response.data.error, {
      root: true
    })
  },
  /**
   *
   * takes the success message from the API, commits
   * and triggers a toast
   *
   * @param {*} context
   * @param {*} data argument provided to dispatch {collection:'foo', url:'bar'}
   * @param {*} message success message from api response
   *
   * @todo remove if's. consolidate into one commit. coordinate api responses.
   *
   */
  handleMessage: function (context, data, message) {
    if (data.message && data.message.length) {
      context.commit(`Global/${types.SET_SUCCESS}`, data.message, {
        root: true
      })
    }

    if (message) {
      context.commit(`Global/${types.SET_SUCCESS}`, message, {
        root: true
      })
    }
  }
}

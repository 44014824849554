<template>
  <v-app>
    <v-navigation-drawer
      v-if="!authLayout"
      v-model="drawer"
      right
      temporary
      app
    >
      <v-list
        dense
        nav
        class="py-0"
      >
        <v-list-item two-line>
          <v-avatar
            color="secondary"
            size="36"
            class="mr-3"
          >
            <span class="white--text text--center" v-if="currentUser">{{ userInitials }}</span>
          </v-avatar>
          <v-list-item-content>
            <v-list-item-title v-if="currentUser">
              {{ currentUser.firstname + ' ' + currentUser.lastname}}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider />
      <v-list v-if="currentUser.role === 'admin'">
        <v-list-item
          
          v-for="(item, index) in $options.navItemsAdmin"
          :key="index"
          link
          :to="item.path"
        >
          <v-list-item-action>
            <v-icon> {{ item.icon }} </v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ item.label }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-list v-if="currentUser.role != 'admin'">
        <v-list-item
          
          v-for="(item, index) in $options.navItemsUser"
          :key="index"
          link
          :to="item.path"
        >
          <v-list-item-action>
            <v-icon> {{ item.icon }} </v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ item.label }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <template v-slot:append>
        <div class="pa-4">
          <v-switch
           v-model="darkmode"
            hide-details
            label="Dark"
          />
        </div>
        <v-divider />
        <div class="pa-4">
          <v-btn
            block
            color="secondary"
            dark
            depressed
            @click.exact="handleLogout"
          >
            Logout
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>
    <v-app-bar
      v-if="!authLayout"
      app
      color="primary"
      dark
    >
      <v-img
        class="mr-3"
        src="@/assets/monigle-logo.svg"
        max-width="50"
        contain
        @click.exact="$router.push('/')"
      />
      <v-divider vertical />
      <v-toolbar-title class="ml-3">
        Dev Ops
      </v-toolbar-title>
      <v-spacer />
      <v-btn
        color="secondary"
        depressed
        large
        @click.stop="drawer = !drawer"
      >
        Menu
        <v-icon right>
          mdi-menu
        </v-icon>
      </v-btn>
    </v-app-bar>
    <v-content>
      <keep-alive>
        <router-view />
      </keep-alive>
    </v-content>
    <v-footer
      color="grey darken-4"
      app
    >
      <span class="white--text">Monigle Associates &copy; 2021 - v2.2.0</span>
      <span class="white--text">This site is protected by reCAPTCHA and the Google
        <a href="https://policies.google.com/privacy">Privacy Policy</a> and
        <a href="https://policies.google.com/terms">Terms of Service</a> apply.
      </span>
    </v-footer>
  </v-app>
</template>
<script>
//import { appLocalStorage } from './utils'


export default {

  
  name: 'app',
  data: () => ({
    drawer: false,
    apiStatus: null,
    darkmode: false,
    snackbar: false
  }),
  navItemsAdmin: [
    {
      path: '/deployments',
      icon: 'mdi-rocket-launch-outline',
      label: 'Deployments'
    },
    {
      path: '/deployments_basic',
      icon: 'mdi-rocket-launch-outline',
      label: 'Deployments Basic'
    },    
    {
      path: '/deployments_github',
      icon: 'mdi-rocket-launch-outline',
      label: 'Deployments Github'
    },     
    {
      path: '/deployments_demo',
      icon: 'mdi-rocket-launch-outline',
      label: 'Deployments Demo'
    },     
    {
      path: '/crawler',
      icon: 'mdi-bug-outline',
      label: 'Bug Crawler'
    },
    {
      path: '/database-export',
      icon: 'mdi-database-cog',
      label: 'Database Export B3'
    },
    {
      path: '/database-copy',
      icon: 'mdi-database-cog',
      label: 'Database Copy'
    },    
    {
      path: '/database-export-b2',
      icon: 'mdi-database-cog',
      label: 'Database Export B2'
    },
    {
      path: '/database-copy-b2',
      icon: 'mdi-database-cog',
      label: 'Database Copy B2'
    },       
    {
      path: '/database-export-non-beam',
      icon: 'mdi-database-cog',
      label: 'Database Non Beam'
    }

  ],
  navItemsUser: [
    {
      path: '/database-export',
      icon: 'mdi-database-cog',
      label: 'Database Export B3'
    } ,
    {
      path: '/database-copy',
      icon: 'mdi-database-cog',
      label: 'Database Copy'
    },     
    {
      path: '/database-copy-b2',
      icon: 'mdi-database-cog',
      label: 'Database Copy B2'
    },        
    {
      path: '/database-export-b2',
      icon: 'mdi-database-cog',
      label: 'Database Export B2'
    },
    {
      path: '/database-export-non-beam',
      icon: 'mdi-database-cog',
      label: 'Database Non Beam'
    }
  ],
  computed: {
    authLayout () {
      return this.$route.name === 'user-auth'
    },
    currentUser () {
      //return this.$store.getters['User/userName']
      const currentUser = (this.$store.getters['User/userData'] !== null) ? this.$store.getters['User/userData'] : null;
      return currentUser;
    },
    
    userInitials () {
      if (this.currentUser != null) {
        return (
          this.currentUser.firstname.charAt(0).toUpperCase() + this.currentUser.lastname.charAt(0).toUpperCase()
        )
      }

      return null
    }
  },
  methods: {
    handleLogout () {
      this.drawer = false
      //appLocalStorage.removeItem('qa-env')
      this.$store.dispatch('User/setProperty', {
        collection: 'data',
        data: null
      })
      this.$router.replace('/user-auth')
    },
    checkAuthToken () {
      /*const qaEnv = appLocalStorage.getItem('qa-env')

      if (!qaEnv) {
        this.$router.replace('/user-auth').catch(() => {})
      } else {
        this.$store.dispatch('User/setProperty', {
          collection: 'data',
          data: qaEnv
        })
      }*/
    },
    handleDarkMode () {
      if (this.darkmode === true) {
        this.$vuetify.theme.dark = true
        localStorage.setItem('DarkMode', true)
      } else if (this.darkmode === false) {
        this.$vuetify.theme.dark = false
        localStorage.setItem('DarkMode', false)
      }
    },
    loadTheme(){    
      if (localStorage.getItem('DarkMode')) {
        const cookieValue = localStorage.getItem('DarkMode') === 'true'
        this.darkmode = cookieValue
      } else {
        this.handleDarkMode()
      }
    }
  },
  watch: {
    darkmode (oldval, newval) {
      this.handleDarkMode()
    }
  },
  created () {
    this.checkAuthToken()
    this.loadTheme()
  }
}
</script>
<style lang="scss" scoped>
  footer.v-footer {
    z-index: 0;
    font-size: 14px;
    justify-content: space-between;
    text-align: center;
    padding: 10px 16px;
    @media(min-width: 768px) {
      span {
        a {
          color: #fff!important;
        }
      }
    }
  }
  .grecaptcha-badge {
    visibility: hidden;
  }
</style>
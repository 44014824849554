import actions from '../actions/actions'
import mutations from '../mutations/mutations'

const getters = {

  userData: (state, getters) => state.data ? state.data : null,

 // jwt: state => state.currentJWT,
  //userName: state => state.data.username
  /*state => {
    if (state.data) {
      switch (state.data) {
        case 'qa1':
          return 'Nick Fisher'
        case 'qa2':
          return 'Avery Chen'
        case 'qa3':
          return 'Anson Smith'
        case 'qa4':
          return 'Grace Lee'
        default:
          return null
      }
    }
    return 'ME DUDE'
  }*/

}

const User = {
  namespaced: true,
  state: {
    loading: false,
    error: false,
    data: null,
  },
  mutations,
  actions,
  getters
}
export default User

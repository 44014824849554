import * as types from '../mutations/mutation-types'
import
{ actionsHelpers }
  from './actions-helpers'
import {
  getResource,
  getNestedResource,
  createResource,
  updateResource,
  patchResource,
  deleteResource
} from '../../services'

export default {
  async getProperty (context, data) {
    const id = actionsHelpers.randomID()
    actionsHelpers.initStatus(context, data.collection)

    if (data.params) {
      data.params = JSON.parse(JSON.stringify(data.params))
    } else {
      data.params = {}
    }

    try {
      const result = await getResource(data)

      context.commit(types.SET_PROPERTY, {
        collection: data.collection,
        data: result[data.element || data.collection]
      })
    } catch (error) {
      actionsHelpers.handleError(error, context, id)
    } finally {
      actionsHelpers.unsetLoadingStatus(context, id)
    }
  },
  async getNestedProperty (context, data) {
    const id = actionsHelpers.randomID()

    actionsHelpers.initStatus(context, id)

    try {
      const result = await getNestedResource(data.url)

      context.commit(types.SET_NESTED_PROPERTY, {
        collection: data.collection,
        data: result[data.element]
      })

      actionsHelpers.handleMessage(context, data, result.message)
    } catch (error) {
      actionsHelpers.handleError(error, context, id)
    } finally {
      actionsHelpers.unsetLoadingStatus(context, id)
    }
  },
  async createProperty (context, data) {
    const id = actionsHelpers.randomID()

    actionsHelpers.initStatus(context, id)

    Object.keys(data.data).forEach(key => {
      data.data[key] === null && delete data.data[key]
    })

    try {
      const result = await createResource(data)

      context.commit(types.SET_PROPERTY, {
        collection: data.collection,
        data: result[data.element || data.collection]
      })

      context.commit(types.SET_PROPERTY_CREATED, result[data.element].id)

      actionsHelpers.handleMessage(context, data, result.message)
    } catch (error) {
      actionsHelpers.handleError(error, context, id)
    } finally {
      actionsHelpers.unsetLoadingStatus(context, id)
    }
  },
  async createNestedProperty (context, data) {
    const id = actionsHelpers.randomID()

    actionsHelpers.initStatus(context, id)

    Object.keys(data.data).forEach((key) => {
      data.data[key] === null && delete data.data[key]
    })

    try {
      const result = await createResource(data)

      context.commit(types.SET_NESTED_PROPERTY, {
        collection: data.collection,
        data: result[data.element]
      })

      context.commit(types.SET_PROPERTY_CREATED, result[data.element].id)
      actionsHelpers.handleMessage(context, data, result.message)
    } catch (error) {
      actionsHelpers.handleError(error, context, id)
    } finally {
      actionsHelpers.unsetLoadingStatus(context, id)
    }
  },
  async updateProperty (context, data) {
    const id = actionsHelpers.randomID()

    actionsHelpers.initStatus(context, id)

    if (Array.isArray(data.data)) {
      data.data.forEach((element) => {
        Object.keys(element)
          .forEach((key) => {
            element[key] === null && delete element[key]
          })
      })
    } else if (typeof data.data === 'object') {
      Object.keys(data.data)
        .forEach((key) => {
          data.data[key] === null && delete data.data[key]
        })
    }

    try {
      const result = await updateResource(data)

      context.commit(types.SET_PROPERTY, {
        collection: data.collection,
        data: result[data.element || data.collection]
      })

      actionsHelpers.handleMessage(context, data, result.message)
    } catch (error) {
      actionsHelpers.handleError(error, context, id)
    } finally {
      actionsHelpers.unsetLoadingStatus(context, id)
    }
  },
  async updateNestedProperty (context, data) {
    const id = actionsHelpers.randomID()

    actionsHelpers.initStatus(context, id)

    Object.keys(data.data).forEach((key) => {
      data.data[key] === null && delete data.data[key]
    })

    try {
      const result = await updateResource(data)

      context.commit(types.SET_NESTED_PROPERTY, {
        collection: data.collection,
        data: result[data.element]
      })

      actionsHelpers.handleMessage(context, data, result.message)
    } catch (error) {
      actionsHelpers.handleError(error, context, id)
    } finally {
      actionsHelpers.unsetLoadingStatus(context, id)
    }
  },
  async patchProperty (context, data) {
    const id = actionsHelpers.randomID()
    actionsHelpers.initStatus(context, id)
    try {
      const result = await patchResource(data)
      context.commit(types.SET_PROPERTY, {
        collection: data.collection,
        data: result[data.element || data.collection]
      })
      actionsHelpers.handleMessage(context, data, result.message)
    } catch (error) {
      actionsHelpers.handleError(error, context, id)
    } finally {
      actionsHelpers.unsetLoadingStatus(context, id)
    }
  },
  async patchNestedProperty (context, data) {
    const id = actionsHelpers.randomID()

    actionsHelpers.initStatus(context, id)

    Object.keys(data.data).forEach((key) => {
      data.data[key] === null && delete data.data[key]
    })

    try {
      const result = await patchResource(data)
      context.commit(types.SET_NESTED_PROPERTY, {
        collection: data.collection,
        data: result[data.element]
      })
      actionsHelpers.handleMessage(context, data, result.message)
    } catch (error) {
      actionsHelpers.handleError(error, context, id)
    } finally {
      actionsHelpers.unsetLoadingStatus(context, id)
    }
  },
  async deleteProperty (context, data) {
    const id = actionsHelpers.randomID()
    actionsHelpers.initStatus(context, id)

    try {
      const result = await deleteResource(data.url)
      context.commit(types.DELETE_PROPERTY, data)
      actionsHelpers.handleMessage(context, data, result.message)
    } catch (error) {
      actionsHelpers.handleError(error, context, id)
    } finally {
      actionsHelpers.unsetLoadingStatus(context, id)
    }
  },
  async deleteNestedProperty (context, data) {
    const id = actionsHelpers.randomID()

    actionsHelpers.initStatus(context, id)

    try {
      const result = await deleteResource(data.url)
      actionsHelpers.handleMessage(context, data, result.message)
      data.response = result
      context.commit(types.DELETE_NESTED_PROPERTY, data)
    } catch (error) {
      actionsHelpers.handleError(error, context, id)
    } finally {
      actionsHelpers.unsetLoadingStatus(context, id)
    }
  },
  setProperty (context, data) {
    context.commit(types.SET_PROPERTY, {
      collection: data.collection,
      data: data.data
    })
  },
  setNestedProperty (context, data) {
    context.commit(types.SET_NESTED_PROPERTY, {
      collection: data.collection,
      data: data.data
    })
  }
}

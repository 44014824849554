import Vue from 'vue'
import VueRouter from 'vue-router'
//import { appLocalStorage } from '../utils'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/deployments'
  },
  {
    path: '/deployments',
    name: 'deployments',
    component: () => import(/* webpackChunkName: "deployments" */ '../views/deployments.vue')
  },
  {
    path: '/deployments_basic',
    name: 'deployments basic',
    component: () => import(/* webpackChunkName: "deployments" */ '../views/deployments_basic.vue')
  },  
  {
    path: '/deployments_demo',
    name: 'deployments demo/product',
    component: () => import(/* webpackChunkName: "deployments" */ '../views/deployments_demo.vue')
  },    
  {
    path: '/deployments_github',
    name: 'Deployments Github Actions',
    component: () => import(/* webpackChunkName: "deployments" */ '../views/deployments_github.vue')
  },    
  {
    path: '/crawler',
    name: 'crawler',
    component: () => import(/* webpackChunkName: "crawler" */ '../views/crawler.vue')
  },
  {
    path: '/database-export',
    name: 'database-export',
    component: () => import(/* webpackChunkName: "database-export" */ '../views/database-export.vue')
  },
  {
    path: '/database-copy',
    name: 'database-copy',
    component: () => import(/* webpackChunkName: "database-export" */ '../views/database-copy.vue')
  },  
  {
    path: '/database-copy-b2',
    name: 'database-copy-b2',
    component: () => import(/* webpackChunkName: "database-export" */ '../views/database-copy-b2.vue')
  },    
  {
    path: '/database-export-b2',
    name: 'database-export-b2',
    component: () => import(/* webpackChunkName: "database-export-b2" */ '../views/database-export-b2.vue')
  },
  {
    path: '/database-export-non-beam',
    name: 'database-export-non-beam',
    component: () => import(/* webpackChunkName: "database-export-b2" */ '../views/database-export-non-beam.vue')
  },
  {
    path: '/user-auth',
    name: 'user-auth',
    component: () => import(/* webpackChunkName: "user-auth" */ '../views/user-auth.vue')
  },
  {
    path: '*',
    name: 'not-found',
    component: () => import(/* webpackChunkName: "not-found" */ '../views/not-found.vue')
  }
]

export const router = new VueRouter({
  routes
})

const routerHelpers = {
  isAuthenticated: function () {
    //const authToken = appLocalStorage.getItem('qa-env')
    const authToken = ( store.getters['User/userData'] !== null ) ? store.getters['User/userData'].JWT_TOKEN : null
    
   
    return !!authToken
  }
}

router.beforeEach((to, from, next) => {
  if (to.name !== 'user-auth' && !routerHelpers.isAuthenticated()) next({ name: 'user-auth' })
  else next()
})

/**
 *
 * Http Client
 *
 * Adds custom header to requests for API to determine active client.
 * Also uses interceptors to add optional Authentication header.
 *
 * Axios Cheat Sheet:
 * @link https://kapeli.com/cheat_sheets/Axios.docset/Contents/Resources/Documents/index
 *
 */

import axios from 'axios'
import { apiBaseUrl } from '../environment'
import store from '../store'
import VueRouter from 'vue-router'

// const config = {
//  baseURL: apiBaseUrl || '/'

/*
  headers: {
    Authorization: 'Bearer 3fe9009f3fc83b6af2f36ad552c018027b91fe3b',
    'Content-Type': 'application/json'
  }
  */
// }

// const httpClient = axios.create(config)

/**
 * Auth Interceptor
 *
 * @description if present, add authorization token to request
 * @param {object} config
 * @returns axios config
 */
// const authInterceptor = config => {
//   const JWT = JSON.parse(CookieManager.getItem('jwt'))

//   if (JWT) config.headers.Authorization = JWT.token

//   return config
// }

// httpClient.interceptors.request.use(authInterceptor)

// LocalstorageService
// not using local storage - const localStorageService = LocalStorageService.getService();

// Add a request interceptor

const baseURL = apiBaseUrl || '/'

const httpClient = axios.create({
  baseURL
})

httpClient.interceptors.request.use(
  config => {
    if (store.getters['User/userData'] !== null) {
      const token = store.getters['User/userData'].JWT_TOKEN // localStorageService.getAccessToken();
      if (token) {
        if (config.url !== 'https://api.github.com/repos/monigle-associates/beam3-lumen-api/pulls' && config.url !== 'https://api.github.com/repos/monigle-associates/beam3-web/pulls') {
          config.headers['Authorization'] = 'Bearer ' + token
        }
      }
    }
    config.headers['Content-Type'] = 'application/json'

    return config
  },
  error => {
    Promise.reject(error)
  })

httpClient.interceptors.response.use((response) => {
  // Do something with response data
  return response

}, async function (error) {
  // if( typeof error.config !== "undefined" ) {

  const originalRequest = error.config

  if (originalRequest.url === 'https://api.github.com/repos/monigle-associates/beam3-lumen-api/pulls' || originalRequest.url === 'https://api.github.com/repos/monigle-associates/beam3-web/pulls') {
    return error // response
  }
  // && originalRequest.url === 'http://localhost:8080/token'
  if (error.response.status === 401 || error.response.status === 403) {
    // router.push('/login');
    VueRouter.replace('/user-auth').catch(() => {})
    return Promise.reject(error)
  }

  if (error.response.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true
    const refreshToken = store.getters['User/userData'].JWT_REFRESH_TOKEN // GET REFRESH TOKEN localStorageService.getRefreshToken()
    const res = await httpClient.post('/auth/token', {
      refresh_token: refreshToken
    })

    if (res.status === 201) {
      // UPDATE TOKEN IN STORE //localStorageService.setToken(res.data);

      var user = JSON.parse(atob(res.refreshToken.split('.')[1]))
      user.JWT_TOKEN = res.refreshToken

      this.$store.dispatch('User/setProperty', {
        collection: 'data',
        data: user
      })

      httpClient.defaults.headers.common['Authorization'] = 'Bearer ' + res.refreshToken
      return httpClient(originalRequest)
    }
  }

  // }

  return Promise.reject(error)

})

export { httpClient }

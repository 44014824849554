import { httpClient } from '../utils'

/**
 * Get Pull Requests
 *
 * @param {string} app selected app to load open pull requests
 *
 */

const headers = {
  Authorization: 'Bearer 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',
  'Content-Type': 'application/json'
}

export function getPullRequests (app = '') {
  return httpClient.get(`https://api.github.com/repos/monigle-associates/${app}/pulls`,{
    headers,
    params: {
      _limit: 50
     }
  }).then(res => res.data)
}

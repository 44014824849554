/**
 * Vuetify
 *
 * Our CSS / Component Library of choice.
 *
 * @reference https://vuetifyjs.com/en/
 *
 */

import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#2d536e',
        secondary: '#4f758b',
        accent: '#ff9731',
        error: '#ec0202',
        info: '#2196F3',
        success: '#214f43',
        warning: '#FFC107'
      },
      dark: {
        primary: '#424242',
        secondary: '#616161'
      }
    }
  }
})

import { httpClient } from '../utils'

/**
 * Build Application
 *
 * @param {object} data containing require info for circleci jobs
 *
 * data = {
 *   application: 'beam3-web',
 *   client: 'bain',
 *   'qa-env': 'qa1',
 *   branch: '
 * }
 *
 *
 */

export function buildApplication (data) {
  
  return httpClient.post(`/build-${data.application === 'beam3-web' ? 'web' : 'api'}`,
    {
      branch: data.branch,
      parameters: {
        client: data.client,
        qa_env: data.env
      }
    }).then(res => {
      return res.data
    })

    /*

    ,
    {
      headers: {
        Authorization: `Bearer 1z${data.authToken}`,
        'Content-Type': 'application/json'
      }
    }
    */
  /*
  return httpClient.post(`/api/build-${data.application === 'beam3-web' ? 'web' : 'api'}`, {
    branch: data.branch,
    parameters: {
      client: data.client,
      'qa-env': data.engineer
    }
  }).then(res => res.data)
  */
}

export function buildApplicationDemo (data) {
  
  return httpClient.post(`/build-demo-${data.application === 'beam3-web' ? 'web' : 'api'}`,
    {
      branch: data.branch,
      parameters: {
        client: data.client,
        qa_env: data.env
      }
    }).then(res => {
      return res.data
    })
}
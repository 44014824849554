import { httpClient } from '../utils'


/**
 * Build Application
 *
 * @param {object} data containing require info for circleci jobs
 *
 * data = {
 *   application: 'beam3-web',
 *   client: 'bain',
 *   'qa-env': 'qa1',
 *   branch: '
 * }
 *
 *
 */

export function databaseDownload (data) {
  
  return httpClient.get(`/database-download`,
    {
      responseType: 'blob',
      params: {
        filePath: data.filePath,
      },
    
    }).then(res => {

        var fileURL = window.URL.createObjectURL(new Blob([res.data]));
        var fileLink = document.createElement('a');
        var m = new Date();
        var m = new Date();
        var dateString =
            m.getUTCFullYear() + "" +
            ("0" + (m.getUTCMonth()+1)).slice(-2) + "" +
            ("0" + m.getUTCDate()).slice(-2) + "_" +
            ("0" + m.getUTCHours()).slice(-2) + "" +
            ("0" + m.getUTCMinutes()).slice(-2) + "" +
            ("0" + m.getUTCSeconds()).slice(-2);
        fileLink.href = fileURL;
        fileLink.setAttribute('download', 'db_dump_'+dateString+'.sql');
        document.body.appendChild(fileLink);
        
        fileLink.click();
        /*const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = label
        link.click()
        URL.revokeObjectURL(link.href)
        */

      //return res.data
    }).catch(console.error)

    /*

    ,
    {
      headers: {
        Authorization: `Bearer 1z${data.authToken}`,
        'Content-Type': 'application/json'
      }
    }
    */
  /*
  return httpClient.post(`/api/build-${data.application === 'beam3-web' ? 'web' : 'api'}`, {
    branch: data.branch,
    parameters: {
      client: data.client,
      'qa-env': data.engineer
    }
  }).then(res => res.data)
  */
}
import { httpClient } from '../utils'

/**
 * Start Bug Crawler
 *
 * @param {object} data containing requirements for running bug crawler
 *
 */
export function startBugCrawler (data = {}) {
  return httpClient.post('/api/random-click', {
    client: data.client,
    url: data.url,
    username: data.username,
    password: data.password
  }).then(res => res.data)
}
